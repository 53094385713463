import { setFontWeight, theme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text, TextInput } from '@lawnstarter/ls-react-common/atoms';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectProspect, updateProspect } from '../../store/modules/prospect/slice';
import { useApplyPromoCodeMutation } from '../../store/modules/prospect/api';
import { InvalidPromoCodeError } from '../../constants/GraphQLErrors';
import { lsI18NService } from '../../service';
import { StyledActionWrapper, StyledClose, StyledWrapper } from './styles';
import { useTrackPayload } from '../../hooks/useTrackPayload';
import { Events } from '../../enums/events';
import { trackPromocodeAdded } from '../../service/segment/trackers';
import { selectApp } from '../../store/modules/app/slice';

const defaultValues = { promoCode: '' };
interface PropsModal {
  hide: () => void;
  setShowToast: (status: boolean) => void;
}

export const PromoCodeModal = (props: PropsModal) => {
  const { hide, setShowToast } = props;
  const dispatch = useDispatch();
  const { id: prospectId, promoCode } = useSelector(selectProspect);
  const [applyPromoCode, { isSuccess, isLoading }] = useApplyPromoCodeMutation();
  const { payloadBuilder } = useTrackPayload(Events.PromocodeAdded);
  const { error } = useSelector(selectApp);

  const { control, getValues, setError } = useForm({
    defaultValues: { promoCode: promoCode || defaultValues.promoCode },
  });

  // Handle validation error
  useEffect(() => {
    const { message, error: invalidPromoCodeError } = InvalidPromoCodeError;
    const isInvalidPromoCode = error === invalidPromoCodeError;

    isInvalidPromoCode && setError('promoCode', { type: 'error', message });
  }, [error, setError]);

  const onApply = useCallback(() => {
    setShowToast(false);

    applyPromoCode({ prospectId, promoCode: getValues().promoCode });
  }, [setShowToast, applyPromoCode, getValues, prospectId]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(updateProspect({ promoCode: getValues().promoCode }));

      const payload = payloadBuilder({ promocode: getValues().promoCode });
      payload && trackPromocodeAdded(payload);

      setShowToast(true);
      hide();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, setShowToast, hide, dispatch, getValues]);

  const titleLabel = promoCode ? lsI18NService.t('promoCode.changeTitle') : lsI18NService.t('promoCode.addTitle');

  return (
    <div style={{ height: '100%', width: '100%', paddingLeft: 15, paddingRight: 15 }}>
      <StyledWrapper>
        <StyledClose onClick={hide} role="button">
          <Icon color={theme.colors.scrim} name="close" size={theme.sizing.s6} />
        </StyledClose>
        <StyledActionWrapper>
          <Text variant="headlineMedium" style={{ ...setFontWeight('600') }}>
            {titleLabel}
          </Text>
          <TextInput
            placeholder={lsI18NService.t('promoCode.placeholder')}
            name="promoCode"
            testID="text-promocode"
            control={control}
            containerStyle={{ flex: 1, marginTop: 10 }}
            rules={{
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9]{4,}$/,
                message: lsI18NService.t('formErrors.invalidPromoCode'),
              },
            }}
          />
        </StyledActionWrapper>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            right: 0,
            marginBottom: 25,
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          <Button
            mode="contained"
            trackID={`apply-promocode`}
            style={{ marginTop: 2 }}
            onPress={onApply}
            disabled={isLoading}
            loading={isLoading}
          >
            {lsI18NService.t('promoCode.apply')}
          </Button>
        </div>
      </StyledWrapper>
    </div>
  );
};
