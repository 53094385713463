import { VerticalSchema } from '../types/schema';
import { StepId, StepType, Vertical } from '../enums/schema';
import { VerticalSelection } from '../pages/Cart/VerticalSelection';
import { ContactInfo } from '../pages/Cart/ContactInfo';
import { Scheduling } from '../pages/Cart/Scheduling';
import { AccountAndPayment } from '../pages/Cart/AccountAndPayment';

export const schema: VerticalSchema = {
  vertical: Vertical.Treatment,
  steps: [
    {
      id: StepId.VerticalSelection,
      type: StepType.Cart,
      component: VerticalSelection,
    },
    {
      id: StepId.ContactInfo,
      type: StepType.Cart,
      component: ContactInfo,
    },
    {
      id: StepId.Scheduling,
      type: StepType.Cart,
      component: Scheduling,
      options: {
        shouldDisplayStartDate: false,
      },
    },
    {
      id: StepId.AccountAndPayment,
      type: StepType.Cart,
      component: AccountAndPayment,
    },
  ],
};
