import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectApp } from '../store/modules/app/slice';
import { ErrorToast } from '../components/ErrorToast';
import { lsI18NService } from '../service';
import { GraphQLCustomErrors, GraphQLHandledErrors } from '../constants/GraphQLErrors';

export function useErrorHandler() {
  const { error } = useSelector(selectApp);

  const isErrorHandled = useMemo(() => (error ? GraphQLHandledErrors.includes(error) : true), [error]);

  const description = useMemo(() => GraphQLCustomErrors.find((e) => e.error === error)?.message, [error]);

  return {
    error,
    component: () =>
      !isErrorHandled && (
        <ErrorToast
          description={description || lsI18NService.t('genericError')}
          msToHide={5000}
          className="fixed w-[98%] mx-[1%] bottom-4 z-10"
        />
      ),
  };
}
