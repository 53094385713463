import { maskPhone, useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, TextInput } from '@lawnstarter/ls-react-common/atoms';
import { Address } from '@lawnstarter/ls-react-common/molecules';

import { StyledStroke } from '../../../../../common/styles';
import { VITE_GOOGLE_API_KEY } from '../../../../../config/env';
import { Step } from '../../../../../layout/Step';
import { lsI18NService } from '../../../../../service';
import { StyledAddressWrapper } from '../../styles';
import { AutocompleteSpinner } from './components/AutocompleteSpinner';
import { RichText } from './components/RichText';
import { ContactInfoFormProps } from './types';

export const ContactInfoForm = ({
  control,
  clearErrors,
  onPress,
  onSetAddress,
  setAddressInput,
  stepId,
}: ContactInfoFormProps) => {
  const { spacing } = useAppTheme();

  return (
    <Step.Form>
      <TextInput
        label={`${lsI18NService.t('contactInfo.name')}*`}
        placeholder={lsI18NService.t('contactInfo.namePlaceholder')}
        name="name"
        rules={{ required: true }}
        control={control}
      />
      <StyledAddressWrapper onClick={() => clearErrors()} autoComplete="off">
        <Address
          name="address1"
          label={`${lsI18NService.t('contactInfo.addressLabel')}*`}
          control={control}
          placeholder={lsI18NService.t('contactInfo.addressPlaceholder')}
          setAddress={onSetAddress}
          googleApiKey={VITE_GOOGLE_API_KEY}
          rules={{ required: true }}
          formatSelectedAddress={({ place }) => `${place.street}, ${place.city} ${place.state} ${place.zip}`}
          onChangeText={setAddressInput}
          loadingSpinner={<AutocompleteSpinner />}
          debounce={0}
          minLength={1}
        />
        <TextInput
          placeholder={lsI18NService.t('contactInfo.address2Placeholder')}
          name="address2"
          control={control}
          containerStyle={{ marginTop: spacing.s2 }}
          rules={{ required: false }}
        />
      </StyledAddressWrapper>
      <TextInput
        label={`${lsI18NService.t('contactInfo.phoneNumber')}*`}
        placeholder={lsI18NService.t('contactInfo.phoneNumberPlaceholder')}
        name="phone"
        keyboardType="phone-pad"
        onMask={maskPhone}
        rules={{
          required: true,
          pattern: {
            value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
            message: lsI18NService.t('formErrors.invalidPhoneNumber'),
          },
        }}
        control={control}
        containerStyle={{ marginTop: spacing.s3 }}
      />
      <StyledStroke className="mt-8 md:hidden" />
      <RichText />
      <Step.Footer>
        <Step.Action>
          <Button onPress={onPress} mode="contained" trackID={`${stepId}-continue`}>
            {lsI18NService.t('continue')}
          </Button>
        </Step.Action>
      </Step.Footer>
    </Step.Form>
  );
};
