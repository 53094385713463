import { useAppTheme } from '@lawnstarter/ls-react-common';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { PickerValidDate } from '@mui/x-date-pickers/models';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isToday from 'dayjs/plugin/isToday';
import dayjs from 'dayjs';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import './style.css';
import { useState } from 'react';

dayjs.extend(isTomorrow);
dayjs.extend(isToday);

export const DatePicker = (props: MuiDatePickerProps<PickerValidDate>) => {
  const [open, setOpen] = useState(false);
  const { colors } = useAppTheme();
  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePicker
          // Defaults
          shouldDisableDate={(date) => dayjs(date).isTomorrow() || dayjs(date).isToday()}
          disablePast={true}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          slotProps={{ textField: { onClick: () => setOpen(!open) } }}
          defaultValue={dayjs(new Date()).add(2, 'days')}
          format="ddd, MMM D, YYYY"
          // Props Injection from target
          {...props}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
