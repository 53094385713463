import { Button, TextInput, Text } from '@lawnstarter/ls-react-common/atoms';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useRef } from 'react';
import { To } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { StyledStroke } from '../../../common/styles';
import { Cart } from '../../../components/Cart';
import { StepTitle } from '../../../components/StepTitle';
import { BackButton } from '../../../components/BackButton';
import { VITE_MY_LOGIN_URL, VITE_STRIPE_API_KEY } from '../../../config/env';
import { ErrorToast } from '../../../components/ErrorToast';
import { Step } from '../../../layout/Step';
import { AccountAndPaymentStep } from '../../../types/schema';
import { StepId, StepType } from '../../../enums/schema';
import { usePromoCode } from '../../../hooks/usePromoCode';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { lsI18NService } from '../../../service';
import { HeaderVariants, updateApp } from '../../../store/modules/app/slice';
import { selectProspect } from '../../../store/modules/prospect/slice';

import { ToSBanner } from './components/ToSBanner';
import { StripeForm } from './components/StripeForm';
import { EMAIL_REGEX, useAccountAndPayment } from './controller';
import {
  StyledFullNameWrapper,
  StyledHeadlineWrapper,
  StyledTextInputWrapper,
  styleDesktopToast,
  styleMobileToast,
} from './styles';
import { TosCheckbox } from './components/TosCheckbox';

const stripePromise = loadStripe(String(VITE_STRIPE_API_KEY));

const emailTakenAction = {
  label: lsI18NService.t('login'),
  onPress: () => window.location.assign(VITE_MY_LOGIN_URL),
};

const stepId = StepId.AccountAndPayment;

export const AccountAndPayment = () => {
  const navigate = useNavigate();
  const { goToNextStep, vertical, hashId, currentStep } = useStepNavigation({ stepId });
  const stripeFormRef = useRef<HTMLFormElement>(null);
  const checkboxRef = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    shouldHighlightError,
    setPaymentToken,
    setIsStripeFormValid,
    isEmailAlreadyTaken,
    form,
    mutation: { data, isSuccess, isLoading },
  } = useAccountAndPayment({ stripeFormRef, checkboxRef });

  const { promocode } = usePromoCode();
  const prospect = useSelector(selectProspect);
  const totalAmount =
    prospect?.selectedServiceEstimates?.reduce(
      (total, service) => total + (service.selectedEstimate!.amount || 0),
      0,
    ) || 0;

  useEffect(() => {
    const authToken = data?.submitProspectAsOrder?.authToken;

    if (isSuccess && authToken) {
      sessionStorage.setItem('authToken', authToken as string);
      navigate(goToNextStep as To);
    }
  }, [data?.submitProspectAsOrder?.authToken, goToNextStep, isSuccess, navigate]);

  useEffect(() => {
    dispatch(updateApp({ shouldShowDetails: false }));
    return () => {
      dispatch(updateApp({ shouldShowDetails: true }));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateApp({ headerRightSlot: HeaderVariants.DetailedBadgePhone }));
    return () => {
      dispatch(updateApp({ headerRightSlot: null }));
    };
  }, [dispatch]);

  return (
    <Step>
      {isEmailAlreadyTaken && (
        <ErrorToast
          className={styleMobileToast}
          description={lsI18NService.t('formErrors.emailAlreadyInUse')}
          action={emailTakenAction}
        />
      )}

      <Step.Body>
        <Step.Content>
          <StyledHeadlineWrapper>
            <BackButton
              pathname={`/${StepType.Cart}/${hashId}/${StepId.Scheduling}`}
              className="mr-8 md:mb-4 h-fit w-fit"
            />
            <StepTitle stepId={stepId} vertical={vertical} />
          </StyledHeadlineWrapper>

          <ToSBanner currentStep={currentStep as AccountAndPaymentStep} className="hidden md:block mb-4" />
        </Step.Content>

        <Step.Form className="pb-48">
          {isEmailAlreadyTaken && (
            <ErrorToast
              className={styleDesktopToast}
              description={lsI18NService.t('formErrors.emailAlreadyInUse')}
              action={emailTakenAction}
            />
          )}

          <StyledTextInputWrapper>
            <Text>{lsI18NService.t('accountAndPayment.nameInput.fullName')}*</Text>
            <StyledFullNameWrapper>
              <TextInput
                placeholder={lsI18NService.t('accountAndPayment.nameInput.firstName')}
                name="firstName"
                testID="text-input-first-name"
                rules={{ required: true }}
                control={form.control}
                containerStyle={{ flex: 1 }}
              />
              <TextInput
                placeholder={lsI18NService.t('accountAndPayment.nameInput.lastName')}
                name="lastName"
                testID="text-input-last-name"
                rules={{ required: true }}
                control={form.control}
                containerStyle={{ flex: 1 }}
              />
            </StyledFullNameWrapper>
          </StyledTextInputWrapper>

          <StyledTextInputWrapper>
            <Text>{lsI18NService.t('accountAndPayment.emailInput.label')}*</Text>
            <TextInput.Email
              name="email"
              testID="text-input-email"
              placeholder={lsI18NService.t('accountAndPayment.emailInput.label')}
              control={form.control}
              rules={{
                required: lsI18NService.t('accountAndPayment.emailInput.requiredMessage'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: lsI18NService.t('accountAndPayment.emailInput.patternMessage'),
                },
              }}
            />
          </StyledTextInputWrapper>

          <StyledStroke />

          <Elements stripe={stripePromise}>
            <StripeForm
              stripeFormRef={stripeFormRef}
              setPaymentToken={setPaymentToken}
              setIsStripeFormValid={setIsStripeFormValid}
            />
          </Elements>

          <ToSBanner currentStep={currentStep as AccountAndPaymentStep} className="md:hidden mb-4" />
          <TosCheckbox shouldHighlightError={shouldHighlightError} form={form} checkboxRef={checkboxRef} />
          <Step.Footer>
            <Step.Action>
              <Cart promocode={promocode} price={totalAmount} />

              <Button onPress={handleSubmit} mode="contained" trackID={`${stepId}-continue`} loading={isLoading}>
                {lsI18NService.t('lawnTreatment.bookFor0')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
