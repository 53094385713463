import { Brand } from '@lawnstarter/ls-react-common/enums';

export interface PhoneData {
  raw: string;
  formatted: string;
}

export interface BrandMetadata {
  displayName: string;
  favicon: string;
  phone: PhoneData;
}

export const METADATA: Record<Brand, BrandMetadata> = {
  [Brand.LawnStarter]: {
    displayName: 'LawnStarter',
    favicon: '/images/lawnstarter/favicon.png',
    phone: {
      raw: '18668221766',
      formatted: '1-866-822-1766',
    },
  },
  [Brand.LawnLove]: {
    displayName: 'Lawn Love',
    favicon: '/images/lawnlove/favicon.png',
    phone: {
      raw: '18007064117',
      formatted: '1-800-706-4117',
    },
  },
};
