import { theme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { VITE_BRAND } from '../../config/env';
import { MOBILE_QUERY } from '../../constants/general';
import { METADATA } from '../../constants/metadata';
import { StepId, Vertical } from '../../enums/schema';
import { lsI18NService } from '../../service';
import { selectApp } from '../../store/modules/app/slice';
import { StyledWrapper, styleSubTitle as styleSubTitleCommon, styleTitle as styleTitleCommon } from './styles';

interface StepTitleProps {
  stepId: StepId;
  vertical?: Vertical;
  titleSlot?: () => JSX.Element;
  centerOnMobile?: boolean;
  title?: string;
}

export const StepTitle = ({
  stepId,
  vertical,
  titleSlot: TitleSlot = () => <></>,
  centerOnMobile = false,
  title,
}: StepTitleProps) => {
  const stepIdKey = _.camelCase(stepId);
  const isMobile = useMediaQuery(MOBILE_QUERY);
  const { shouldShowDetails } = useSelector(selectApp);
  const shouldRenderDetails = shouldShowDetails || !isMobile;
  const onMobile = centerOnMobile && isMobile ? { textAlign: 'center' } : {};
  const styleSubTitleMobile = isMobile
    ? { ...onMobile, fontSize: theme.sizing.s4, lineHeight: theme.sizing.s5 }
    : { ...onMobile };

  const { displayName } = METADATA[VITE_BRAND as Brand];

  return (
    <StyledWrapper>
      <Text variant="headlineMedium" style={{ ...styleTitleCommon, ...onMobile }}>
        {title || lsI18NService.t(`${stepIdKey}.title`, { vertical, brand: displayName })} <TitleSlot />
      </Text>
      {shouldRenderDetails && (
        <Text
          variant="headlineSmall"
          data-testid="step-desc"
          style={{ ...styleSubTitleCommon, ...styleSubTitleMobile }}
        >
          {lsI18NService.t(`${stepIdKey}.description`, { vertical })}
        </Text>
      )}
    </StyledWrapper>
  );
};
