import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Spinner, Text, Toast } from '@lawnstarter/ls-react-common/atoms';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StepTitle } from '../../../components/StepTitle';
import { StyledStroke } from '../../../common/styles';
import { DatePicker } from '../../../components/DatePicker';
import { StepId } from '../../../enums/schema';
import { useSchema } from '../../../hooks/useSchema';
import { Step } from '../../../layout/Step';
import { lsI18NService } from '../../../service';
import { HeaderVariants, updateApp } from '../../../store/modules/app/slice';
import { ErrorScreen } from '../../ErrorScreen';
import { useScheduling } from './controller';
import { StyledToast, StyledWrapperLink, StyledFieldWrapper, styleDate } from './styles';
import { Selector } from '../../../components/Selector';
import { usePromoCode } from '../../../hooks/usePromoCode';
import { centsToDollar } from '../../../helpers/currency';
import { CompactFaq } from './components/CompactFaq';
import { Banner } from '../../../components/Banner';
import { TOAST_MS_TO_HIDE } from '../../../constants/general';
import { Promocode } from './components/Promocode';
import { Cart } from '../../../components/Cart';
import { Events } from '../../../enums/events';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { trackNotLookingForClicked, trackStartDateSubmitted } from '../../../service/segment/trackers';
import { selectProspect } from '../../../store/modules/prospect/slice';
import { Status } from '../../../enums/prospect';
import { BinaryModalWrapper } from '../../../components/BinaryModalWrapper';
import { useWwwLead } from './useWwwLead';

const stepId = StepId.Scheduling;

export const Scheduling = () => {
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const { vertical } = useSchema();
  const { property, selectOptions, hasError, query, form, shouldDisplayStartDate, currentStep } = useScheduling();
  const prospect = useSelector(selectProspect);
  const { address1: street, city, zip } = property;
  const [showToast, setShowToast] = useState(false);
  const { openModal, promocode, title: promoTitle } = usePromoCode();
  const { payloadBuilder: startDatePayload } = useTrackPayload(Events.StartDateSubmitted);
  const { payloadBuilder } = useTrackPayload(Events.NotLookingForClicked);
  const { landscapingUrl } = useWwwLead();

  useEffect(() => {
    dispatch(updateApp({ headerRightSlot: HeaderVariants.DetailedBadgePhone, shouldShowAddress: true }));

    return () => {
      dispatch(updateApp({ shouldShowAddress: false }));
    };
  }, [dispatch]);

  const onDate = (date: dayjs.Dayjs | null) => {
    if (!date) {
      return;
    }

    const payload = startDatePayload({ startDate: date.format('YYYY-MM-DD') });
    payload && trackStartDateSubmitted(payload);
  };

  const onImNotLooking = (e?: React.MouseEvent) => {
    e?.preventDefault();
    const payload = payloadBuilder();
    payload && trackNotLookingForClicked(payload);
    window.location.href = landscapingUrl;
  };

  return query.isLoading || prospect?.status === Status.Completed ? (
    <Spinner size={theme.sizing.s13} />
  ) : hasError ? (
    <ErrorScreen
      image={{ name: 'property', isBranded: false }}
      title={lsI18NService.t('calculationScreen.error.provideQuote')}
      label={`${street}, ${city}, ${zip}`}
    />
  ) : (
    <Step>
      <Step.Body>
        <Step.Content>
          <StepTitle title={promoTitle} stepId={stepId} vertical={vertical} titleSlot={() => <CompactFaq />} />
          <Banner
            className="hidden md:block"
            title={lsI18NService.t('scheduling.banner.title')}
            icon="lightbulb-outline"
          >
            {lsI18NService.t('scheduling.banner.description')}
          </Banner>
        </Step.Content>
        <Step.Form className="pb-56">
          {selectOptions.map((estimate) => (
            <Selector
              key={estimate.key}
              title={estimate.label}
              caption={lsI18NService.t(`scheduling.caption`)}
              price={`$${centsToDollar(estimate.price, 0)}`}
              onClick={() => void form.setEstimate(estimate.entity)}
              isSelected={form.estimate.id === estimate.key}
              isRecommended={currentStep.options?.recommendedCycle === estimate.entity.cycle}
            />
          ))}

          {shouldDisplayStartDate && (
            <StyledFieldWrapper>
              <Text variant="bodyMedium" style={styleDate}>
                {lsI18NService.t('scheduling.dateLabel')}
              </Text>
              <DatePicker
                value={form.startDate}
                onChange={(date: dayjs.Dayjs | null) => date && form.setStartDate(date)}
                onAccept={onDate}
              />
            </StyledFieldWrapper>
          )}

          <Promocode promocode={promocode} onOpen={() => openModal({ setShowToast })} />

          <StyledStroke className="mt-8 md:hidden" />

          <Banner className="md:hidden" title={lsI18NService.t('scheduling.banner.title')} icon="lightbulb-outline">
            {lsI18NService.t('scheduling.banner.description')}
          </Banner>
          <BinaryModalWrapper
            icon="home-alert-outline"
            onConfirm={onImNotLooking}
            description={lsI18NService.t('scheduling.imNotLooking.description')}
          >
            <StyledWrapperLink>{lsI18NService.t('scheduling.imNotLooking.label', { vertical })}</StyledWrapperLink>
          </BinaryModalWrapper>

          {showToast && (
            <StyledToast>
              <Toast
                icon="check-circle-outline"
                variant="success"
                description={lsI18NService.t('promoCode.codeApplied')}
                msToHide={TOAST_MS_TO_HIDE}
              />
            </StyledToast>
          )}

          <Step.Footer>
            <Step.Action>
              <Cart promocode={promocode} price={form.estimate.amount ?? 0} />

              <Button
                onPress={form.handleSubmit}
                mode="contained"
                trackID={`${stepId}-continue`}
                loading={form.mutation.isLoading || form.mutation.isSuccess}
                disabled={!form.isValid || form.mutation.isLoading || form.mutation.isSuccess}
              >
                {lsI18NService.t('scheduling.continue')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
